import React from 'react';
import "./WhoCanApplyDetails.scss";

const WhoCanApplyDetails = () => {
    return (
        <div className="who-can-apply-details">
            <div className="who-can-apply-details-header">
                <h2>who can apply?</h2>
            </div>
        </div>
    );
};

export default WhoCanApplyDetails;